<template>
  <div>
    <data-iterator :headers="payloadHeaders" :payload="payload" class="ma-2"> </data-iterator>
  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      listOfForms: [],
      modelObj: {},
      payload: {
        // showBackBtn: false,
        create: () => this.$router.push('/forms/actions'),
        items: [],
        showSelect: false,
        actionsList: [{
          click: this.editHandler,
          icon: 'mdi-pencil',
          color: 'primary'
        }, {
          show: (item) => !item.isinternal,
          click: (item) => this.$root.$emit('delete', {
            module: 'forms',
            _id: item._id,
            onResolve: () => this.getListHandler()
          }),
          // click: (item) => this.deleteHandler(item._id),
          icon: 'mdi-delete',
          color: 'error'
        }]
      }
    }
  },
  components: {
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  mounted () {
    this.getListHandler()
  },
  computed: {
    payloadHeaders () {
      return [{
        text: 'Name',
        value: 'name'
      }, {
        text: 'Is Active',
        value: 'isactive',
        type: 'BOOLEAN'
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  methods: {
    getListHandler () {
      this.$_execute('get', 'forms')
        .then(({ data }) => {
          this.payload.items = data
        })
    },
    editHandler ({ _id }) {
      this.$router.push(`/forms/actions/${_id}`)
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'forms',
        _id: id,
        onResolve: () => {
          this.dialog = false
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getListHandler()
        }
      })
    }
  }
}
</script>
